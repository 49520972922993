import * as React from "react";
import { Link } from "gatsby";
import "./not-found.scss";
import { navigate } from "gatsby-link";

// markup
const NotFoundPage = () => {

  const [display,setDisplay] = React.useState(false)

  setTimeout(() => {
    setDisplay(true)
  }, 5000);

  return (
    <>
   {display && <div className="not-found">
      <div className="container">
        <h1 className="first-four">4</h1>
        <div className="cog-wheel1">
          <div className="cog1">
            <div className="top"></div>
            <div className="down"></div>
            <div className="left-top"></div>
            <div className="left-down"></div>
            <div className="right-top"></div>
            <div className="right-down"></div>
            <div className="left"></div>
            <div className="right"></div>
          </div>
        </div>

        <div className="cog-wheel2">
          <div className="cog2">
            <div className="top"></div>
            <div className="down"></div>
            <div className="left-top"></div>
            <div className="left-down"></div>
            <div className="right-top"></div>
            <div className="right-down"></div>
            <div className="left"></div>
            <div className="right"></div>
          </div>
        </div>
        <h1 className="second-four">4</h1>
       
      </div>
      <div className="wrong-para">
          <p>Page Not Found!!</p>
          <button onClick={()=>navigate("/onboarding/step-one")}>Login</button>
        </div>
    </div>}
    </>
  );
};

export default NotFoundPage;
